import React from "react";
import './Home.css';
import ContactForm from "./ContactForm";
import plane from '../images/plane.svg';
import logo from '../images/logo.svg';

function Home() {
    return (
        <React.Fragment>
            <div className="desktop">
                <div className="overlap-wrapper">
                    <div className="images">
                        {/* <img className="plane" alt="Plane" src={plane} /> */}
                        <div className="logo-wrapper">
                        <img className="logo" alt="Logo" src={logo} />
                        </div>
                    </div>
                    <div className="frame">
                        <h1 className="title">blablab</h1>
                        <div className="slogan-wrapper">
                            <h2 className="slogan">Hop on the ride before it takes off!</h2>
                        </div>
                        <p className="first-to-know">
                            <span className="info">be the first to know as soon as </span>
                            <span className="highlight">blablab</span>
                            <span className="info"> is available for download</span>
                        </p>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Home;