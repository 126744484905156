import React, { useState } from "react";
import Select from 'react-select';
import { components } from 'react-select';
import './ContactForm.css';
import SuccessMessage from "./SuccessMessage";


function ContactForm() {
    const [email, setEmail] = useState("");
    const [learnLanguage, setLearnLanguage] = useState("");
    const [learnLanguageValue, setLearnLanguageValue] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const languages = [
        { code: "zh", name: "Chinese" },
        { code: "nl", name: "Dutch" },
        { code: "en", name: "English" },
        { code: "fr", name: "French" },
        { code: "de", name: "German" },
        { code: "el", name: "Greek" },
        { code: "he", name: "Hebrew" },
        { code: "hi", name: "Hindi" },
        { code: "it", name: "Italian" },
        { code: "ja", name: "Japanese" },
        { code: "ko", name: "Korean" },
        { code: "pt", name: "Portuguese" },
        { code: "ru", name: "Russian" },
        { code: "es", name: "Spanish" },
        { code: "sv", name: "Swedish" },
        { code: "th", name: "Thai" },
        { code: "tr", name: "Turkish" },
        { code: "uk", name: "Ukrainian" },
        { code: "ur", name: "Urdu" },
        { code: "vi", name: "Vietnamese" }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        fetch('https://stagingapi.goblablab.com/marketing_contacts/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, learn_language: learnLanguageValue }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setSubmitted(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                setError('Submission failed. Please try again.');
            });
    };

    const languageOptions = languages.map(lang => ({
        value: lang.code,
        label: lang.name
    }));

    const handleChange = (selectedOption) => {
        setLearnLanguage(selectedOption || []);
        setLearnLanguageValue(selectedOption.value || []);
    };

    const Input = (props) => <components.Input {...props} readOnly />;

    return (
        <React.Fragment>
            {submitted ? (
                <SuccessMessage />
            ) : (
                <form onSubmit={handleSubmit} className="email-form">
                    <div className="input-wrapper">
                        <input
                            type="email"
                            className="email-input"
                            placeholder="email@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="select-wrapper">
                        <Select
                            // isMulti
                            name="language"
                            options={languageOptions}
                            className="basic-select"
                            value={learnLanguage}
                            onChange={handleChange}
                            components={{ Input }}
                        />
                    </div>
                    <div className="button-wrapper">
                        <button type="submit" className="sign-up-button">Sign me up!</button>
                    </div>
                    {error && <p>{error}</p>}
                </form>
            )}
        </React.Fragment>
    );
}

export default ContactForm;