import React from "react";
import './SuccessMessage.css';

function SuccessMessage() {
    return (
        <React.Fragment>
            <h1>Thanks for joining our journey!</h1>
        </React.Fragment>
    );
}

export default SuccessMessage;